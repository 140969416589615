import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { cx } from "emotion";

const ImportMaterialCommunityIcon = (loadFromSmartabase) => {
  if (!window.materialDesignIconImported) {
    // community material design icons import
    const mdiTag = document.createElement("link");

    // when loadFromSmartabase and in production, load icon from smartabase.
    if (loadFromSmartabase && process.env.NODE_ENV === "production") {
      // getting base url for current smartabase site
      const { origin } = window.location;
      const appName = window.location.href.split("/")[3];
      const siteURLDesktop = `${origin}/${appName}`;
      const baseUrl = window.serverURL !== undefined ? window.serverURL : siteURLDesktop;

      mdiTag.href = `${baseUrl}/css/materialdesignicons.css`;
    } else {
      // otherwise load icon from their cdn
      mdiTag.href = "https://cdn.materialdesignicons.com/2.6.95/css/materialdesignicons.min.css";
    }

    mdiTag.rel = "stylesheet";
    mdiTag.type = "text/css";
    document.head.appendChild(mdiTag);

    window.materialDesignIconImported = true;
  }
};
const ImportFontAwesomeIcon = () => {
  if (!window.fontAwesomeIconImported) {
    // font awesome icons import
    const fontAwesomeTag = document.createElement("style");
    const fontAwesomeCss =
      "@import url(https://cdn.smartabase.com/fontawesome/v1/css/font-awesome-css.min.css);@font-face{font-family:FontAwesome;src:url(https://cdn.smartabase.com/fontawesome/v1/fonts/fontawesome-webfont.eot);src:url(https://cdn.smartabase.com/fontawesome/v1/fonts/fontawesome-webfont.eot?#iefix) format('embedded-opentype'),url(https://cdn.smartabase.com/fontawesome/v1/fonts/fontawesome-webfont.woff2) format('woff2'),url(https://cdn.smartabase.com/fontawesome/v1/fonts/fontawesome-webfont.woff) format('woff'),url(https://cdn.smartabase.com/fontawesome/v1/fonts/fontawesome-webfont.ttf) format('truetype'),url(https://cdn.smartabase.com/fontawesome/v1/fonts/fontawesome-webfont.svg#fontawesomeregular) format('svg');font-weight:400;font-style:normal}";
    fontAwesomeTag.innerText = fontAwesomeCss;
    document.head.appendChild(fontAwesomeTag);

    window.fontAwesomeIconImported = true;
  }
};

const IconWrapper = styled("span")(
  ({ size }) => `
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${size}px;
  width: ${size}px;
`
);

const WebIcon = styled("span")(
  ({ color, size }) => `
  color: ${color};
  font-size: ${size}px;
`
);

const Icon = ({ name, size, color, font, className, iconClassName, loadFromSmartabase }) => {
  let computedClassName = name;
  let fontFamily = "";
  switch (font) {
    case "FontAwesome":
      computedClassName = `fab fa-${computedClassName}`;
      fontFamily = "FontAwesome";
      ImportFontAwesomeIcon();
      break;
    default:
      computedClassName = `mdi mdi-${computedClassName}`;
      ImportMaterialCommunityIcon(loadFromSmartabase);
  }

  return (
    <IconWrapper className={className} size={size}>
      <WebIcon
        className={cx(computedClassName, iconClassName)}
        color={color}
        size={size}
        style={{
          fontFamily
        }}
      />
    </IconWrapper>
  );
};

export const SvgIcon = ({ path, size = 24, fill }) => (
  <svg width={size} height={size} viewBox={"0 0 24 24"} fill={fill}>
    <svg d={path} />
  </svg>
);

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  size: PropTypes.number,
  color: PropTypes.string,
  font: PropTypes.oneOf(["FontAwesome", "MaterialCommunity"]),
  className: PropTypes.string,
  iconClassName: PropTypes.string,
  loadFromSmartabase: PropTypes.bool
};

Icon.defaultProps = {
  size: 12,
  color: "black",
  font: "MaterialCommunity",
  className: undefined,
  iconClassName: undefined,
  loadFromSmartabase: false
};

export default Icon;
