import { ThemeProvider } from "emotion-theming";
import PropTypes from "prop-types";
import React from "react";
import { defaultTheme } from "./themes";

const DefaultThemeProvider = ({ children }) => (
  <ThemeProvider theme={defaultTheme}>{children}</ThemeProvider>
);

DefaultThemeProvider.defaultProps = {
  children: null
};

DefaultThemeProvider.propTypes = {
  children: PropTypes.node
};

export default DefaultThemeProvider;
