import {
  lightTheme,
  darkTheme,
  lightThemeIndustry,
  darkThemeIndustry,
  kioskTheme,
  defaultTheme,
  defaultDashboardTheme
} from "./themes";
import ThemePropTypes from "./ThemePropTypes";
import DefaultThemeProvider from "./DefaultThemeProvider";
import getContrasts from "./utils/getContrasts";
import createTheme from "./createTheme";
import renderWithTheme from "./renderWithTheme";
import { COLORS as COLOR_CONSTANTS } from "./constants";

export {
  darkTheme,
  lightTheme,
  darkThemeIndustry,
  lightThemeIndustry,
  kioskTheme,
  defaultDashboardTheme,
  ThemePropTypes,
  DefaultThemeProvider,
  getContrasts,
  renderWithTheme,
  createTheme,
  COLOR_CONSTANTS
};
export default defaultTheme;
