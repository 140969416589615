import PropTypes from "prop-types";

const ThemePropType = PropTypes.shape({
  background: PropTypes.shape({
    main: PropTypes.string.isRequired,
    surface: PropTypes.string.isRequired,
    accent: PropTypes.string.isRequired,
    contrastHigh: PropTypes.string.isRequired,
    contrastMedium: PropTypes.string.isRequired,
    contrastLow: PropTypes.string.isRequired,
    contrastVeryLow: PropTypes.string.isRequired
  }).isRequired,
  primary: PropTypes.shape({
    main: PropTypes.string.isRequired,
    dark: PropTypes.string.isRequired,
    light: PropTypes.string.isRequired,
    contrastHigh: PropTypes.string.isRequired,
    contrastMedium: PropTypes.string.isRequired,
    contrastLow: PropTypes.string.isRequired,
    contrastVeryLow: PropTypes.string.isRequired
  }).isRequired,
  secondary: PropTypes.shape({
    main: PropTypes.string.isRequired,
    dark: PropTypes.string.isRequired,
    light: PropTypes.string.isRequired,
    contrastHigh: PropTypes.string.isRequired,
    contrastMedium: PropTypes.string.isRequired,
    contrastLow: PropTypes.string.isRequired,
    contrastVeryLow: PropTypes.string.isRequired
  }).isRequired,
  success: PropTypes.shape({
    main: PropTypes.string.isRequired,
    contrastHigh: PropTypes.string.isRequired,
    contrastMedium: PropTypes.string.isRequired,
    contrastLow: PropTypes.string.isRequired,
    contrastVeryLow: PropTypes.string.isRequired
  }).isRequired,
  error: PropTypes.shape({
    main: PropTypes.string.isRequired,
    contrastHigh: PropTypes.string.isRequired,
    contrastMedium: PropTypes.string.isRequired,
    contrastLow: PropTypes.string.isRequired,
    contrastVeryLow: PropTypes.string.isRequired
  }).isRequired,
  info: PropTypes.shape({
    main: PropTypes.string.isRequired,
    contrastHigh: PropTypes.string.isRequired,
    contrastMedium: PropTypes.string.isRequired,
    contrastLow: PropTypes.string.isRequired,
    contrastVeryLow: PropTypes.string.isRequired
  }).isRequired,
  warning: PropTypes.shape({
    main: PropTypes.string.isRequired,
    contrastHigh: PropTypes.string.isRequired,
    contrastMedium: PropTypes.string.isRequired,
    contrastLow: PropTypes.string.isRequired,
    contrastVeryLow: PropTypes.string.isRequired
  }).isRequired,
  greys: PropTypes.objectOf(PropTypes.string.isRequired),
  hyperlink: PropTypes.shape({
    main: PropTypes.string.isRequired,
    visited: PropTypes.string.isRequired
  }).isRequired,
  spacing: PropTypes.shape({
    unit: PropTypes.number.isRequired,
    xs: PropTypes.string.isRequired,
    sm: PropTypes.string.isRequired,
    md: PropTypes.string.isRequired,
    lg: PropTypes.string.isRequired,
    xl: PropTypes.string.isRequired,
    xxl: PropTypes.string.isRequired
  }).isRequired,
  font: PropTypes.shape({
    size: PropTypes.string.isRequired,
    bodyFontFamily: PropTypes.string.isRequired,
    headerFontFamily: PropTypes.string.isRequired
  }).isRequired,
  shadows: PropTypes.shape({
    elevation1: PropTypes.string.isRequired,
    elevation4: PropTypes.string.isRequired
  }).isRequired,
  borderRadius: PropTypes.shape({
    body: PropTypes.string.isRequired,
    controls: PropTypes.string.isRequired
  }).isRequired
});

export default ThemePropType;
