/* eslint-disable no-shadow, no-prototype-builtins, no-param-reassign */
import merge from "lodash/merge";
import { bodyFontFamily as bodyFont, headerFontFamily as headerFont } from "@fs/CoreFonts";
import {
  blue,
  common,
  green,
  red,
  yellow,
  grey,
  lightBackground,
  lightBackgroundDisabled
} from "./colors";
import { darken, lighten, getLuminance } from "./utils/colorManipulator";
import {
  defaultSpacing,
  defaultFontSize,
  themeSchema,
  defaultShadows,
  defaultBorderRadius
} from "./config";
import getContrasts, { darkContrasts } from "./utils/getContrasts";

const createTheme = (theme = {}) => {
  const {
    themeType = "light",
    background = {
      main: lightBackground.main,
      surface: lightBackground.surface,
      accent: lightBackground.accent
    },
    primary = {
      ...darkContrasts, // we default to shades of white for the primary green, as getContrasts resolves navy
      light: green[300],
      main: green[500],
      dark: green[700]
    },
    secondary = {
      main: blue[500],
      light: blue[300],
      dark: blue[700]
    },
    success = {
      main: green[500],
      light: green[300],
      dark: green[700]
    },
    error = {
      main: red[500],
      light: red[300],
      dark: red[700]
    },
    info = {
      main: blue[500],
      light: blue[300],
      dark: blue[900]
    },
    warning = {
      main: yellow[500],
      light: yellow[100],
      dark: yellow[900]
    },
    greys = {
      main: grey.main,
      light: grey.light,
      dark: grey.dark
    },
    hyperlink = {
      main: blue[500],
      visited: blue[900]
    },
    disabled = {
      main: lightBackgroundDisabled.main,
      inverted: lightBackgroundDisabled.inverted
    },
    spacing = defaultSpacing,
    font = {
      size: defaultFontSize,
      bodyFontFamily: bodyFont,
      headerFontFamily: headerFont
    },
    borderRadius = defaultBorderRadius,
    // The difference between main and light/dark colors
    tonalOffset = 0.2,
    ...other
  } = theme;

  // Shadows needs to be reassigned if not chosen
  let { shadows } = theme;

  function addLightOrDark(intent, direction, tonalOffset) {
    // Lighten and darkend colours for light and dark colors
    // https://github.com/mui-org/material-ui/blob/master/packages/material-ui/src/styles/createPalette.js
    if (!intent[direction]) {
      if (direction === "light") {
        intent.light = lighten(intent.main, tonalOffset);
      } else if (direction === "dark") {
        intent.dark = darken(intent.main, tonalOffset * 1.5);
      }
    }
  }

  function augmentColor(color, addContrasts = true) {
    // Add light, dark and contrast colours to our colors
    // https://github.com/mui-org/material-ui/blob/master/packages/material-ui/src/styles/createPalette.js

    if (process.env.NODE_ENV !== "production" && !color.main) {
      throw new Error(
        [
          "Theme Provider: The color provided to augmentColor(color) is invalid.",
          "The color object needs to have a `main` property."
        ].join("\n")
      );
    }

    addLightOrDark(color, "light", tonalOffset);
    addLightOrDark(color, "dark", tonalOffset);
    if (addContrasts && !color.contrastHigh) {
      const contrasts = getContrasts(color.main);
      Object.assign(color, contrasts);
    }
  }

  augmentColor(background);
  // Set accent and surface to be the same as the background color
  // if not provided
  if (!background.surface) {
    background.surface = background.main;
  }
  if (!background.accent) {
    background.accent = background.main;
  }
  augmentColor(primary);
  augmentColor(secondary);
  augmentColor(error);
  augmentColor(success);
  augmentColor(info);
  augmentColor(warning);
  augmentColor(greys, false);
  greys.black = common.black;
  greys.white = common.white;

  // Add shades of black and white on to the greys object
  getContrasts(greys.white);

  // Decide on shadow
  if (!shadows) {
    // shadows not yet defined
    if (getLuminance(background.main) > 0.5) {
      // is light/bright/white
      shadows = {
        ...defaultShadows.light
      };
    } else {
      shadows = {
        ...defaultShadows.dark
      };
    }
  }

  // Decide fonts
  if (!font.size) font.size = defaultFontSize;
  if (!font.bodyFontFamily) font.bodyFontFamily = bodyFont;
  if (!font.headerFontFamily) font.headerFontFamily = headerFont;

  const themeOutput = merge(
    {
      themeType,
      // The background, surface and text colours used for the majority of the app
      background,
      // The colors used to represent primary interface elements for a user.
      primary,
      // The colors used to represent secondary interface elements for a user.
      secondary,
      // The colors used to represent interface elements that the user should be made aware of.
      error,
      // The colors used to represent interface elements that indicate success
      success,
      // The colors used to represent interface elements that give the user information
      info,
      // The colors used to represent interface elements that give the user a non critical warning
      warning,
      // The colors used for hyperlinks
      hyperlink,
      // Greys to be used on an app basis
      greys,
      // The unit of spacing and spacing sizes
      spacing,
      // The properties that relate to the font
      font,
      // The box-shadows for different elevations
      shadows,
      // Disabled colours
      disabled,
      // The border radius for Controls and Body
      borderRadius,
      // Common colours like black and white
      common
    },
    other
  );

  try {
    themeSchema.validateSync(themeOutput, { strict: true });
  } catch (err) {
    throw Error(
      `Theme Provider: Sorry your theme is invalid because of the following errors: ${err.errors.join(
        ","
      )}`
    );
  }

  return themeOutput;
};

export default createTheme;
