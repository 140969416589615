import createTheme from "../createTheme";
import { blue, red, darkBackground, darkBackgroundDisabled } from "../colors";

const darkTheme = {
  themeType: "dark",
  background: {
    main: darkBackground.main,
    surface: darkBackground.surface,
    accent: darkBackground.accent
  },
  secondary: {
    main: red[500]
  },
  hyperlink: {
    main: blue[500],
    visited: blue[300]
  },
  disabled: {
    main: darkBackgroundDisabled.main,
    inverted: darkBackgroundDisabled.inverted
  }
};

export default createTheme(darkTheme);
