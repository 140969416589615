export const navy = {
  50: "#F4F7FB",
  100: "#D9DDE4",
  200: "#818B9A",
  300: "#596474",
  400: "#334155",
  500: "#1B283B",
  600: "#162130",
  700: "#0B111F",
  800: "#080D14",
  900: "#070A0F"
};

export const green = {
  50: "#C2FFD5",
  100: "#5DF18B",
  200: "#5BEB88",
  300: "#2EDF69",
  400: "#13D557",
  500: "#00C848",
  600: "#00AC3D",
  700: "#00793A",
  800: "#00513A",
  900: "#002B29"
};

export const blue = {
  100: "#009CFF",
  300: "#0F73E2",
  500: "#009CFF",
  700: "#0B58AA",
  900: "#0A4C96"
};

export const yellow = {
  100: "#FFB522",
  300: "#FFAA01",
  500: "#FFAB10",
  700: "#EA9B00",
  900: "#D89000"
};

export const red = {
  100: "#FFECEC",
  300: "#FFECEC",
  500: "#FF3838",
  700: "#D72700",
  900: "#D72700"
};

export const common = {
  white: "#FFFFFF",
  black: "#000000"
};

export const darkBackground = {
  accent: "#1B283B",
  main: "#162130",
  surface: "#2C3D54"
};

export const lightBackground = {
  accent: "#F5F5F5",
  main: "#FFFFFF",
  surface: "#FFFFFF"
};

export const darkBackgroundDisabled = {
  main: "#494F56",
  inverted: "#73777C"
};

export const lightBackgroundDisabled = {
  main: "#D0D0D0",
  inverted: "#94999F"
};

export const grey = {
  light: "#B0B5B5",
  main: "#717375",
  dark: "#3B3C3D"
};
