import { transparentize } from "polished";
import logWarning from "warning";
import { getContrastRatio } from "./colorManipulator";
import { common } from "../colors";

const lightContrastOpacities = {
  high: 1 - 1,
  medium: 1 - 0.75,
  low: 1 - 0.5,
  veryLow: 1 - 0.15
};
export const lightContrasts = {
  contrastHigh: transparentize(lightContrastOpacities.high, "#333333"),
  contrastMedium: transparentize(lightContrastOpacities.medium, "#333333"),
  contrastLow: transparentize(lightContrastOpacities.low, "#333333"),
  contrastVeryLow: transparentize(lightContrastOpacities.veryLow, "#333333")
};

const darkContrastOpacities = {
  high: 1 - 1,
  medium: 1 - 0.75,
  low: 1 - 0.5,
  veryLow: 1 - 0.15
};
export const darkContrasts = {
  contrastHigh: transparentize(darkContrastOpacities.high, common.white),
  contrastMedium: transparentize(darkContrastOpacities.medium, common.white),
  contrastLow: transparentize(darkContrastOpacities.low, common.white),
  contrastVeryLow: transparentize(darkContrastOpacities.veryLow, common.white)
};

// Contrast ratio for AA accessibility standards text on background
const contrastThreshold = 3;

export default function getContrasts(background) {
  // Use the same logic as
  // Bootstrap: https://github.com/twbs/bootstrap/blob/1d6e3710dd447de1a200f29e8fa521f8a0908f70/scss/_functions.scss#L59
  // and material-components-web https://github.com/material-components/material-components-web/blob/ac46b8863c4dab9fc22c4c662dc6bd1b65dd652f/packages/mdc-theme/_functions.scss#L54
  // Modified version of getContrastText from material ui
  // https://github.com/mui-org/material-ui/blob/master/packages/material-ui/src/styles/createPalette.js

  // Check if dark contrasts meet the contrast threshold and use dark else use light
  const contrasts =
    getContrastRatio(background, darkContrasts.contrastHigh) >= contrastThreshold
      ? darkContrasts
      : lightContrasts;
  if (process.env.NODE_ENV !== "production") {
    const contrast = getContrastRatio(background, contrasts.contrastHigh);
    // Currently this warning shouldn't be
    // reached because users cannot define their own contrast colours
    logWarning(
      contrast >= 3,
      [
        `Theme Provider: The contrast ratio of ${contrast}:1 for ${contrasts.contrastHigh} on ${background}`,
        "falls below the WACG recommended absolute minimum contrast ratio of 3:1.",
        "https://www.w3.org/TR/2008/REC-WCAG20-20081211/#visual-audio-contrast-contrast"
      ].join("\n")
    );
  }

  return contrasts;
}
