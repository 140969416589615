import { sourceSansFontFamily } from "@fs/CoreFonts";
import createTheme from "../createTheme";

const kioskTheme = {
  background: {
    main: "#111924",
    surface: "#172538"
  },
  primary: {
    main: "#4876DD",
    light: "#65EDFF"
  },
  secondary: {
    main: "#F55631",
    light: "#F8AB02"
  },
  hyperlink: {
    main: "#4876DD",
    visited: "#65EDFF"
  },
  font: {
    bodyFontFamily: sourceSansFontFamily,
    headerFontFamily: sourceSansFontFamily
  }
};

export default createTheme(kioskTheme);
