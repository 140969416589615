const ufc = require("./ufc.json");
const athlete = require("./athlete.json");
const ussf = require("./ussf.json");
const ta = require("./ta.json");
const combine = require("./combine.json");
const nbamh = require("./nbamh.json");
const tactical = require("./tactical.json");
const insep = require("./insep.json");
const asc = require("./asc.json");
const builder = require("./builder.json");

module.exports = { ufc, athlete, asc, insep, ta, tactical, ussf, combine, nbamh, builder };
