import { sourceSansFontFamily } from "@fs/CoreFonts";
import createTheme from "../createTheme";
import { defaultFontSize } from "../config";

const defaultDashboardTheme = {
  font: {
    size: defaultFontSize,
    bodyFontFamily: sourceSansFontFamily,
    headerFontFamily: sourceSansFontFamily
  }
};

export default createTheme(defaultDashboardTheme);
