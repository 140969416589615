import { ThemeSchemaWeb } from "./schema";

export const defaultSpacing = {
  unit: 8,
  xs: "4px",
  sm: "8px",
  md: "12px",
  lg: "16px",
  xl: "24px",
  xxl: "48px"
};

export const defaultFontSize = "16px";

export const defaultShadows = {
  light: {
    elevation1:
      "0px 1px 3px rgba(153, 153, 153, 0.2), 0px 2px 2px rgba(153, 153, 153, 0.12), 0px 0px 2px rgba(153, 153, 153, 0.38)",
    elevation4:
      "0px 1px 10px rgba(153, 153, 153, 0.2), 0px 4px 5px rgba(153, 153, 153, 0.12), 0px 2px 4px rgba(153, 153, 153, 0.14)"
  },
  dark: {
    elevation1:
      "0px 1px 3px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.12), 0px 0px 2px rgba(0, 0, 0, 0.14)",
    elevation4:
      "0px 1px 10px rgba(7, 10, 15, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.14)"
  }
};

export const defaultBorderRadius = {
  body: "2px",
  controls: "2px"
};

export const themeSchema = ThemeSchemaWeb;
