import { css } from "emotion";

/**
 * We assume a root font size of 16px, and use rems
 * Letter spacing is using px. Is this fine?
 */
export default new Map([
/**
 * Header 1 - 6
 * These are rendered as their normal tags
 * They use header font
 */
["h1", {
  style: css`
        font-size: 6rem;
        font-weight: 700;
        line-height: 1.2;
        letter-spacing: 6px;
        text-transform: uppercase;
      `,
  as: "h1",
  fontFamily: "headerFontFamily"
}], ["h2", {
  style: css`
        font-size: 3.75rem;
        font-weight: 700;
        line-height: 1.2;
        letter-spacing: 4px;
        text-transform: uppercase;
      `,
  as: "h2",
  fontFamily: "headerFontFamily"
}], ["h3", {
  style: css`
        font-size: 3rem;
        font-weight: 700;
        line-height: 1.2;
        letter-spacing: 3px;
        text-transform: uppercase;
      `,
  as: "h3",
  fontFamily: "headerFontFamily"
}], ["h4", {
  style: css`
        font-size: 2.125rem;
        font-weight: 400;
        line-height: 1.2;
        letter-spacing: 2px;
        text-transform: uppercase;
      `,
  as: "h4",
  fontFamily: "headerFontFamily"
}], ["h5", {
  style: css`
        font-size: 1.5rem;
        font-weight: 700;
        line-height: 1.2;
        letter-spacing: 1.5px;
        text-transform: uppercase;
      `,
  as: "h5",
  fontFamily: "headerFontFamily"
}], ["h6", {
  style: css`
        font-size: 1.25rem;
        font-weight: 400;
        line-height: 1.2;
        letter-spacing: 1px;
        text-transform: uppercase;
      `,
  as: "h6",
  fontFamily: "headerFontFamily"
}],
/**
 * Subtitle
 * This uses body font in bold
 * and is rendered as a p tag
 */
["subtitle", {
  style: css`
        font-size: 1rem;
        font-weight: 700;
        line-height: 1.5;
        letter-spacing: 0.15px;
      `,
  as: "p",
  fontFamily: "bodyFontFamily"
}],
/**
 * Body
 * These render as p tags
 * They use body font in regular
 */
["body", {
  style: css`
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        letter-spacing: 0.5px;
      `,
  as: "p",
  fontFamily: "bodyFontFamily"
}], ["body2", {
  style: css`
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1.5;
        letter-spacing: 0.5px;
      `,
  as: "p",
  fontFamily: "bodyFontFamily"
}],
/**
 * Button
 * This renders as a span
 * Header font
 */
["button", {
  style: css`
        font-size: 0.875rem;
        font-weight: 700;
        line-height: 1.2;
        letter-spacing: 1px;
        text-transform: uppercase;
      `,
  as: "span",
  fontFamily: "headerFontFamily"
}], ["navCaption", {
  style: css`
        font-size: 0.75rem;
        font-weight: 700;
        line-height: 1.3;
        letter-spacing: 0.8px;
        text-transform: uppercase;
      `,
  as: "span",
  fontFamily: "headerFontFamily"
}],
/**
 * caption
 * Header font
 * This renders as a span.
 */
["caption", {
  style: css`
        font-size: 0.75rem;
        font-weight: 400;
        line-height: 1.3;
        letter-spacing: 0.4px;
        text-transform: uppercase;
      `,
  as: "span",
  fontFamily: "headerFontFamily"
}],
/**
 * Description
 */
["description", {
  style: css`
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1.2;
        letter-spacing: 0.4px;
      `,
  as: "span",
  fontFamily: "bodyFontFamily"
}],
/**
 * Overline
 * This renders as a span(?)
 * Body font in bold
 */
["overline", {
  style: css`
        font-size: 0.75rem;
        font-weight: 700;
        line-height: 1.3;
        letter-spacing: 1px;
        text-transform: uppercase;
      `,
  as: "span",
  fontFamily: "headerFontFamily"
}],
/**
 * Text Tags
 */
["textTags", {
  style: css`
        font-size: 0.75rem;
        font-weight: 700;
        line-height: 1.2;
        letter-spacing: 0.2px;
      `,
  as: "span",
  fontFamily: "bodyFontFamily"
}], ["badge", {
  style: css`
        font-size: 0.5rem;
        font-weight: 700;
        line-height: 1.2;
        letter-spacing: 0px;
      `,
  as: "span",
  fontFamily: "bodyFontFamily"
}],
/**
 * headerFont and bodyFont render as h1 and p
 * The only styling is they add the fonts
 */
["headerFont", {
  style: null,
  as: "h1",
  fontFamily: "headerFontFamily"
}], ["bodyFont", {
  style: null,
  as: "p",
  fontFamily: "bodyFontFamily"
}]]);