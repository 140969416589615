import { cx, css } from "emotion";
import { ThemePropTypes } from "@fs/theme";
import { withTheme } from "emotion-theming";
import PropTypes from "prop-types";
import React from "react";
import variantsMap from "./variantsMap";

const TypographyRender = ({ variant, as, className, theme, ...otherProps }) => {
  // Get correct variant or default to body - PropTypes will throw a warning if wrong
  const selectedVariant = variantsMap.get(variant) || variantsMap.get("body");
  // Get font from theme, fallback to arial or sans-serif
  const fontFamily = css`
    font-family: ${theme.font[selectedVariant.fontFamily]}, arial, sans-serif;
  `;
  // Decide what html tag to use. Use the provided, or the variants default
  const HTMLTag = as || selectedVariant.as;
  return <HTMLTag {...otherProps} className={cx(fontFamily, selectedVariant.style, className)} />;
};

TypographyRender.defaultProps = {
  variant: "body",
  as: null,
  className: null
};

TypographyRender.propTypes = {
  variant: PropTypes.oneOf([...variantsMap.keys()]),
  as: PropTypes.string,
  className: PropTypes.string,
  theme: ThemePropTypes.isRequired
};

export default withTheme(TypographyRender);
