import Yup from "yup";

const greysSchema = Yup.object()
  .shape({
    white: Yup.string().required(),
    main: Yup.string().required(),
    light: Yup.string().required(),
    dark: Yup.string().required(),
    black: Yup.string().required()
  })
  .required();

const hyperlinkSchema = Yup.object()
  .shape({
    main: Yup.string().required(),
    visited: Yup.string().required()
  })
  .required();

const colorSchema = Yup.object()
  .shape({
    main: Yup.string().required(),
    light: Yup.string().required(),
    dark: Yup.string().required(),
    contrastHigh: Yup.string().required(),
    contrastMedium: Yup.string().required(),
    contrastLow: Yup.string().required(),
    contrastVeryLow: Yup.string().required()
  })
  .required();

const backgroundSchema = Yup.object()
  .shape({
    main: Yup.string().required(),
    surface: Yup.string().required(),
    accent: Yup.string().required(),
    contrastHigh: Yup.string().required(),
    contrastMedium: Yup.string().required(),
    contrastLow: Yup.string().required(),
    contrastVeryLow: Yup.string().required()
  })
  .required();

const shadowsSchema = Yup.object()
  .shape({
    elevation1: Yup.string().required(),
    elevation4: Yup.string().required()
  })
  .required();

const borderRadiusSchema = Yup.object()
  .shape({
    body: Yup.string().required(),
    controls: Yup.string().required()
  })
  .required();

export const ThemeSchemaWeb = Yup.object()
  .shape({
    background: backgroundSchema,
    primary: colorSchema,
    secondary: colorSchema,
    success: colorSchema,
    error: colorSchema,
    info: colorSchema,
    warning: colorSchema,
    hyperlink: hyperlinkSchema,
    greys: greysSchema,
    shadows: shadowsSchema,
    borderRadius: borderRadiusSchema,
    spacing: Yup.object()
      .shape({
        unit: Yup.number().required(),
        xs: Yup.string().required(),
        sm: Yup.string().required(),
        md: Yup.string().required(),
        lg: Yup.string().required(),
        xl: Yup.string().required(),
        xxl: Yup.string().required()
      })
      .required(),
    font: Yup.object()
      .shape({
        size: Yup.string().required(),
        bodyFontFamily: Yup.string().required(),
        headerFontFamily: Yup.string().required()
      })
      .required()
  })
  .required();

export const ThemeSchemaNative = Yup.object()
  .shape({
    background: backgroundSchema,
    primary: colorSchema,
    secondary: colorSchema,
    success: colorSchema,
    error: colorSchema,
    info: colorSchema,
    warning: colorSchema,
    hyperlink: hyperlinkSchema,
    greys: greysSchema,
    spacing: Yup.object()
      .shape({
        unit: Yup.number().required(),
        xs: Yup.number().required(),
        sm: Yup.number().required(),
        md: Yup.number().required(),
        lg: Yup.number().required(),
        xl: Yup.number().required(),
        xxl: Yup.number().required()
      })
      .required(),
    font: Yup.object()
      .shape({
        size: Yup.number().required(),
        bodyFontFamily: Yup.string().required(),
        headerFontFamily: Yup.string().required()
      })
      .required()
  })
  .required();
