import { industryFontFamily } from "@fs/CoreFonts";
import createTheme from "../createTheme";
import lightTheme from "./lightTheme";

const lightThemeIndustry = {
  ...lightTheme,
  font: {
    ...lightTheme.font,
    headerFontFamily: industryFontFamily
  }
};

export default createTheme(lightThemeIndustry);
