import { darkThemeIndustry, createTheme } from "@fs/theme";
import athleteAppTheme from "../themes/athlete.json"; // Fusion Sport

export const defaultTheme = createTheme({ ...darkThemeIndustry,
  custom: {
    appName: athleteAppTheme.appName,
    appLogo: athleteAppTheme.appLogo,
    appPhone: athleteAppTheme.appPhone,
    appFavicon: athleteAppTheme.appFavicon,
    appAppleURL: athleteAppTheme.appAppleURL,
    appAndroidURL: athleteAppTheme.appAndroidURL,
    type: "dark",
    translations: athleteAppTheme.translations
  }
});
export const generateCustomTheme = theme => {
  const generatedThemeSchema = { ...defaultTheme,
    background: {
      main: theme.background,
      surface: theme.surface,
      accent: theme.accent
    },
    primary: {
      main: theme.brand
    },
    custom: { ...defaultTheme.custom,
      appName: theme.appName,
      appLogo: theme.appLogo,
      appPhone: theme.appPhone,
      appFavicon: theme.appFavicon,
      appAppleURL: theme.appAppleURL,
      appAndroidURL: theme.appAndroidURL
    }
  };

  if (theme.translations) {
    generatedThemeSchema.custom.translations = theme.translations;
  }

  return createTheme(generatedThemeSchema);
};