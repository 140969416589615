import { industryFontFamily } from "@fs/CoreFonts";
import createTheme from "../createTheme";
import darkTheme from "./darkTheme";

const darkThemeIndustry = {
  ...darkTheme,
  font: {
    ...darkTheme.font,
    headerFontFamily: industryFontFamily
  }
};

export default createTheme(darkThemeIndustry);
