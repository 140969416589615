var _urlQueryParams$get;

function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

/** @jsx jsx */
import { hot } from "react-hot-loader";
import React, { useEffect } from "react";
import Typography from "@fs/Typography";
import Button from "@fs/Button";
import { useTheme } from "emotion-theming";
import appStoreBadge from "./img/app-store-badge.png";
import googlePlayBadge from "./img/google-play-badge.png";
import styled from "./config/styled";
import { jsx, Global } from "@emotion/core";
import siteFavicon from "./img/fusion/ams_hex.svg";
import QRCode from "react-qr-code";
import { formatUrl } from "./urlFormatter";
const QR_CODE_SIZE = 280;
const urlQueryParams = new URLSearchParams(window.location.search);
const SERVER = (_urlQueryParams$get = urlQueryParams.get("server")) === null || _urlQueryParams$get === void 0 ? void 0 : _urlQueryParams$get.replace(/\/$/, "").replace(/^https:\/\//, "");
const SITE = urlQueryParams.get("site");
const IS_REACT_APP = urlQueryParams.has("loginType") && urlQueryParams.get("loginType") === "react";
const TOKEN = urlQueryParams.get("token");
const GET_STARTED_URL = `https://${SERVER}/${SITE}/${IS_REACT_APP ? "auth" : ""}?page=magicLink&token=${TOKEN}`;
const ATHLETE_DOWNLOAD_URL = window.location.href;
const Container = styled.div(({
  theme
}) => ({
  flex: 1,
  display: "flex",
  height: "100vh",
  maxWidth: "1200px",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  marginRight: "auto",
  marginLeft: "auto",
  textAlign: "center",
  "> *": {
    margin: theme.spacing.lg
  },
  "@media(min-width: 800px)": {
    textAlign: "left"
  },
  "@media(min-width: 1200px)": {
    flexDirection: "row"
  }
}));
const Column = styled.div(({
  theme
}) => ({
  display: "flex",
  flexDirection: "column",
  padding: theme.spacing.xl,
  "@media(min-width: 800px)": {
    "> * + *": {
      marginLeft: theme.spacing.xl
    }
  }
}));
const GetStartedButton = styled(Button)(({
  theme
}) => ({
  padding: theme.spacing.lg,
  paddingLeft: theme.spacing.xxl,
  paddingRight: theme.spacing.xxl,
  marginLeft: "auto",
  marginRight: "auto",
  "@media(min-width: 800px)": {
    marginLeft: 0,
    marginRight: 0
  },
  borderRadius: "6px"
}));
const TextContainer = styled.div({
  justifyContent: "center",
  alignItems: "center",
  display: "flex",
  flexDirection: "column",
  flex: 1,
  "@media(min-width: 800px)": {
    alignItems: "flex-start"
  }
});
const DownloadBadgeContainer = styled.div(({
  theme
}) => ({
  display: "flex",
  flexDirection: "column",
  flexBasis: "80%",
  "> *": {
    maxWidth: 200
  },
  "> * + *": {
    marginTop: theme.spacing.md
  },
  "@media(min-width: 800px)": {
    flexDirection: "row",
    "> * + *": {
      marginTop: 0,
      marginLeft: theme.spacing.md
    }
  },
  "@media(min-width: 1200px)": {
    flexDirection: "column",
    "> * + *": {
      marginTop: theme.spacing.md,
      marginLeft: 0
    }
  }
}));
const DownloadBadge = styled.img({
  width: "100%",
  maxWidth: 260,
  height: "auto"
});
const BodyText = styled(Typography)(({
  theme
}) => ({
  fontFamily: "Manrope, Arial, sans-serif",
  margin: 0,
  marginBottom: theme.spacing.xl
}));

const NewTypography = ({
  style,
  children,
  ...otherProps
}) => {
  return jsx(Typography, _extends({
    style: {
      fontFamily: "Manrope, Arial, sans-serif",
      textTransform: "none",
      ...style
    }
  }, otherProps), children);
};

const AppBrowser = () => {
  var _getAthleteDownloadUr4;

  const theme = useTheme();
  const fontSize = parseInt(theme.font.size, 10);

  const getUrl = url => {
    try {
      const isValidQuery = query => {
        return !!query && query !== "null" && query;
      };

      if (!url || !isValidQuery(SERVER) || !isValidQuery(SITE) || !isValidQuery(TOKEN)) throw url;
      return new URL(url);
    } catch (e) {
      console.log(`Error generating URL: ${url}`);
      return null;
    }
  };

  const getStartedUrl = getUrl(GET_STARTED_URL);
  let getAthleteDownloadUrl = getUrl(ATHLETE_DOWNLOAD_URL);

  if (getAthleteDownloadUrl !== null) {
    var _getAthleteDownloadUr, _getAthleteDownloadUr2, _getAthleteDownloadUr3;

    const queryParams = new URLSearchParams((_getAthleteDownloadUr = getAthleteDownloadUrl) === null || _getAthleteDownloadUr === void 0 ? void 0 : _getAthleteDownloadUr.searchParams);
    queryParams.set("server", formatUrl(String(queryParams.get("server"))));
    getAthleteDownloadUrl = new URL(`${(_getAthleteDownloadUr2 = getAthleteDownloadUrl) === null || _getAthleteDownloadUr2 === void 0 ? void 0 : _getAthleteDownloadUr2.origin}${(_getAthleteDownloadUr3 = getAthleteDownloadUrl) === null || _getAthleteDownloadUr3 === void 0 ? void 0 : _getAthleteDownloadUr3.pathname}?${queryParams.toString()}`);
  }

  useEffect(() => {
    document.title = `${theme.custom.appName} - Onboarding`;
    const favicon = document.createElement("link");
    favicon.rel = "icon";
    favicon.href = siteFavicon;
    document.head.appendChild(favicon);
  }, []);
  const appStoreBadgeComponent = jsx("a", {
    href: theme.custom.appAppleURL,
    target: "_BLANK",
    rel: "noreferrer"
  }, jsx(DownloadBadge, {
    src: appStoreBadge,
    alt: `Get ${theme.custom.appName} on the Apple App Store`
  }));
  const googlePlayBadgeComponent = jsx("a", {
    href: theme.custom.appAndroidURL,
    target: "_BLANK",
    rel: "noreferrer"
  }, jsx(DownloadBadge, {
    src: googlePlayBadge,
    alt: `Get ${theme.custom.appName} on the Google Play Store`
  }));
  const leftColumnStyle = {
    flex: 1,
    alignItems: "flex-start",
    alignSelf: "stretch",
    marginRight: theme.spacing.xs,
    maxWidth: 760,
    "@media(min-width: 1200px)": {
      maxWidth: "100%",
      flexBasis: "75%"
    }
  };
  const rightColumnStyle = {
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    "@media(min-width: 800px)": {
      flexDirection: "row"
    },
    "@media(min-width: 1200px)": {
      flexBasis: "25%",
      flexDirection: "column",
      textAlign: "center",
      "> * + *": {
        marginLeft: 0,
        marginTop: theme.spacing.md
      }
    }
  };

  const t = (phrase, replacers) => {
    let localPhrase = theme.custom.translations[phrase];

    if (replacers) {
      Object.keys(replacers).forEach(key => {
        const regex = new RegExp("{{" + key + "}}", "gi");
        localPhrase = localPhrase.replace(regex, replacers[key]);
      });
    }

    return localPhrase;
  };

  return jsx(React.Fragment, null, jsx(Global, {
    styles: {
      body: {
        flex: 1,
        margin: 0,
        padding: 0,
        backgroundColor: `${theme.background.main}`,
        backgroundImage: `radial-gradient(at right bottom, ${theme.primary.main}, ${theme.background.main})`,
        color: theme.background.contrastHigh
      }
    }
  }), jsx("div", null, jsx(Container, null, jsx(Column, {
    css: leftColumnStyle
  }, jsx(TextContainer, null, jsx(NewTypography, {
    variant: "h3",
    style: {
      margin: 0,
      marginBottom: theme.spacing.xl,
      fontWeight: 700
    }
  }, t("desktop_title", {
    appName: theme.custom.appName
  })), jsx(BodyText, {
    variant: "body",
    style: {
      fontSize: fontSize * 1.25,
      maxWidth: 650,
      fontWeight: 400
    }
  }, t("desktop_description", {
    appName: theme.custom.appName
  })), jsx(BodyText, {
    variant: "subtitle",
    style: {
      fontSize: fontSize * 1.25,
      maxWidth: 650,
      fontWeight: 400
    }
  }, t("desktop_call_to_action")), jsx("a", {
    href: getStartedUrl === null || getStartedUrl === void 0 ? void 0 : getStartedUrl.href,
    title: "Get Started",
    style: {
      textDecoration: "none"
    }
  }, jsx(GetStartedButton, {
    variant: "contained",
    disabled: !getStartedUrl
  }, jsx(NewTypography, {
    variant: "button",
    style: {
      fontSize: fontSize * 1.25,
      margin: 0,
      marginRight: theme.spacing.sm,
      fontWeight: 500
    }
  }, t("desktop_get_started_button")))), !getStartedUrl ? jsx(NewTypography, {
    variant: "body2",
    style: {
      color: theme.error.main,
      fontWeight: 500
    }
  }, t("desktop_error")) : null)), jsx(Column, {
    css: rightColumnStyle
  }, getAthleteDownloadUrl ? jsx(React.Fragment, null, jsx("div", {
    style: {
      height: QR_CODE_SIZE,
      backgroundColor: "#FFF",
      padding: 10
    }
  }, jsx(QRCode, {
    level: "M",
    size: QR_CODE_SIZE,
    value: (_getAthleteDownloadUr4 = getAthleteDownloadUrl) === null || _getAthleteDownloadUr4 === void 0 ? void 0 : _getAthleteDownloadUr4.toString()
  })), jsx("div", null, jsx(NewTypography, {
    variant: "body",
    style: {
      fontWeight: 400
    }
  }, t("desktop_qr_code")))) : null, jsx(DownloadBadgeContainer, null, appStoreBadgeComponent, googlePlayBadgeComponent)))));
};

export default hot(module)(AppBrowser);