function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

/** @jsx jsx */
import { hot } from "react-hot-loader";
import React, { useEffect } from "react";
import Typography from "@fs/Typography";
import { useTheme } from "emotion-theming";
import styled from "./config/styled";
import Button from "@fs/Button";
import { jsx, Global } from "@emotion/core";
import appLogo from "./img/fusion/logo-fusion.png";
import appFavicon from "./img/fusion/ams_hex.svg";
import { isIOS } from "react-device-detect";
import Icon from "@fs/Icon";
import { formatUrl } from "./urlFormatter";
const Container = styled.div(({
  theme
}) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "start",
  padding: theme.spacing.xl
}));
const TextContainer = styled.div({
  justifyContent: "center",
  display: "flex",
  flexDirection: "column",
  flex: 1
});
const Link = styled.a(({
  theme
}) => ({
  color: theme.primary.light
}));
const Logo = styled.img({
  width: "auto",
  height: 50
});
const LinkButton = styled(Button)(({
  theme
}) => ({
  paddingTop: theme.spacing.md,
  paddingBottom: theme.spacing.md,
  borderRadius: "6px",
  width: "100%"
}));

const NewTypography = ({
  style,
  children,
  ...otherProps
}) => {
  return jsx(Typography, _extends({
    style: {
      fontFamily: "Manrope, Arial, sans-serif",
      textTransform: "none",
      ...style
    }
  }, otherProps), children);
};

const AppMobile = () => {
  const theme = useTheme();
  useEffect(() => {
    document.title = `${theme.custom.appName} - Onboarding`;
    const favicon = document.createElement("link");
    favicon.rel = "icon";
    favicon.href = appFavicon;
    document.head.appendChild(favicon);
  }, [theme.custom.appName]);

  const t = (phrase, replacers) => {
    let localPhrase = theme.custom.translations[phrase];

    if (replacers) {
      Object.keys(replacers).forEach(key => {
        const regex = new RegExp("{{" + key + "}}", "gi");
        localPhrase = localPhrase.replace(regex, replacers[key]);
      });
    }

    return localPhrase;
  };

  const formattedParams = new URLSearchParams(window.location.search);

  if (formattedParams.get("server")) {
    formattedParams.set("server", formatUrl(String(formattedParams.get("server"))));
  }

  return jsx(React.Fragment, null, jsx(Global, {
    styles: {
      body: {
        margin: 0,
        padding: 0,
        backgroundColor: "#0F1621",
        color: theme.background.contrastHigh
      }
    }
  }), jsx(Container, null, jsx("div", {
    style: {
      width: "100%",
      flex: 1,
      alignItems: "center",
      justifyItems: "center",
      marginBottom: theme.spacing.xl
    }
  }, jsx(Logo, {
    src: appLogo,
    alt: theme.custom.appName,
    style: {
      flex: 1,
      flexShrink: 1,
      paddingRight: theme.spacing.xl,
      borderRightWidth: 1,
      borderRightColor: theme.background.contrastMedium,
      borderRightStyle: "solid",
      marginRight: theme.spacing.xl
    }
  }), jsx(Logo, {
    src: appFavicon,
    style: {
      flex: 1,
      flexGrow: 1,
      borderRadius: theme.borderRadius.controls
    }
  })), jsx(TextContainer, null, jsx(NewTypography, {
    variant: "h5",
    style: {
      margin: 0,
      marginBottom: theme.spacing.md,
      fontWeight: 700,
      textTransform: "none"
    }
  }, t("title")), jsx(NewTypography, {
    style: {
      margin: 0,
      marginBottom: theme.spacing.xl,
      fontWeight: 400
    }
  }, t("instructions", {
    appName: theme.custom.appName
  })), jsx(NewTypography, {
    variant: "h6",
    style: {
      margin: 0,
      color: theme.primary.main,
      fontWeight: 700
    }
  }, t("step-1")), jsx(NewTypography, null, t("step-1-text", {
    appName: theme.custom.appName
  }), ":"), jsx("a", {
    href: isIOS ? theme.custom.appAppleURL : theme.custom.appAndroidURL,
    target: "_blank",
    style: {
      textDecoration: "none",
      display: "block",
      width: "100%"
    },
    rel: "noreferrer"
  }, jsx(LinkButton, {
    variant: "contained"
  }, jsx(Icon, {
    size: 24,
    name: isIOS ? "apple" : "google-play",
    color: theme.primary.contrastHigh
  }), jsx(NewTypography, {
    as: "span",
    style: {
      marginLeft: theme.spacing.lg,
      marginTop: -1,
      fontWeight: 500
    }
  }, t(isIOS ? "download-app-store" : "download-google-play")))), jsx(NewTypography, {
    variant: "h6",
    style: {
      margin: 0,
      marginTop: theme.spacing.xl,
      color: theme.primary.main,
      fontWeight: "bold"
    }
  }, t("step-2")), jsx(NewTypography, {
    style: {
      marginBottom: 0
    }
  }, t("step-2-text")), jsx(NewTypography, {
    variant: "h6",
    style: {
      margin: 0,
      marginTop: theme.spacing.xl,
      color: theme.primary.main,
      fontWeight: "bold"
    }
  }, t("step-3")), jsx(NewTypography, null, t("step-3-text")), jsx("a", {
    href: `${theme.custom.appName === "Teamworks AMS" ? "smartabase" : "athleteapp"}://?${formattedParams.toString()}`,
    title: t("desktop_get_started_button"),
    style: {
      textDecoration: "none",
      display: "block",
      width: "100%"
    }
  }, jsx(LinkButton, {
    variant: "contained"
  }, jsx(NewTypography, {
    as: "span",
    style: {
      fontWeight: 500
    }
  }, t("desktop_get_started_button")))), jsx(NewTypography, {
    variant: "h6",
    style: {
      margin: 0,
      marginTop: theme.spacing.xxl,
      color: theme.primary.main,
      fontWeight: "bold"
    }
  }, t("what-is", {
    appName: theme.custom.appName
  })), jsx(NewTypography, {
    variant: "body",
    style: {
      color: theme.background.contrastMedium,
      marginBottom: 0
    }
  }, t("description", {
    appName: theme.custom.appName
  })), jsx(NewTypography, {
    as: "ul",
    style: {
      paddingLeft: 25,
      color: theme.background.contrastMedium
    }
  }, jsx("li", null, t("point1")), jsx("li", null, t("point2")), jsx("li", null, t("point3")), jsx("li", null, t("point4"))))));
};

export default hot(module)(AppMobile);