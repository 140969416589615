export const removeProtocol = url => {
  return url.replace(/https?:\/\//gi, "");
};
export const formatUrl = (url, includeHTTPS = false, removeTrailingSlash = true) => {
  if (typeof url === "undefined") {
    return "";
  }

  const prefix = includeHTTPS ? "https://" : "";
  let newUrl = removeProtocol(url);

  if (removeTrailingSlash) {
    newUrl = newUrl.trim().replace(/\/$/g, "");
  }

  return `${prefix}${newUrl}`;
};